/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */

import(/* webpackPrefetch: true */ "../utils/header.js");
import(/* webpackPrefetch: true */ "../utils/footer.js");
//Load fontface
import(
	/* webpackPrefetch: true */ /* webpackChunkName: 'swiper' */ "root/utils/swiper.js"
);

//loader
window.addEventListener("DOMContentLoaded", function (event) {
	// document.querySelector(".loading-first").classList.add("hided");
	if (document.querySelector('[id^="downloadPDF"]')) {
		document.querySelectorAll('[id^="downloadPDF"]').forEach((el) => {
			const subject = el.getAttribute("data-subject");
			const file = el.getAttribute("data-file");
			el.querySelector("#pdf-subject").setAttribute("value", subject);
			el.querySelector("#pdf-filedownload").setAttribute("value", file);
		});
	}
});

let filterDates = document.querySelectorAll(
	".top-keywords-filter-date .previous-date, .top-keywords-filter-date .next-date"
);
if (filterDates) {
	filterDates.forEach((filterDate) => {
		filterDate.addEventListener("click", (e) => {
			let dateValue = filterDate.parentElement.querySelector(
				".top-keywords-filter-date-value"
			);
			let stringDate = dateValue.innerHTML.split("-");
			let date = new Date(stringDate[2], stringDate[1] - 1, stringDate[0]);
			let changeDate = e.target.classList.contains("next-date")
				? date.setDate(date.getDate() + 1)
				: date.setDate(date.getDate() - 1);
			let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
				changeDate
			);
			let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
				changeDate
			);
			let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
				changeDate
			);
			dateValue.innerHTML = `${da}-${mo}-${ye}`;
		});
	});
}
import $ from "jquery";
import { viewport } from "root/utils/viewport";

function anchorLinkHandler(e) {
	const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top);
	e.preventDefault();
	const targetID = this.getAttribute("href");
	const targetAnchor = document.querySelector(targetID);
	if (!targetAnchor) return;
	const originalTop = distanceToTop(targetAnchor);
	const headerHeight = viewport == "desktop" ? 100 : 55;
	window.scrollBy({
		top: originalTop - headerHeight,
		left: 0,
		behavior: "smooth",
	});
}

import { Modal } from "bootstrap"
let registerForm = document.querySelectorAll("#register-button");

if(registerForm){
	registerForm.forEach((item) => item.onclick = function(){
		const myModal = new Modal(document.getElementById('register-form'));
  		myModal.show();
	})
}
const linksToAnchors = document.querySelectorAll('a[href^="#"]');
linksToAnchors.forEach((each) => (each.onclick = anchorLinkHandler));

$(document).on("click", ".backdrop", (e) => {
	$(e.target).parent().parent().find(".search-icon").trigger("click");
	// $(e.target).closest(".search-icon").trigger("click");
});

const getPosition = (selector) => {
  let positionElement = $(selector)?.first()?.offset()?.top;
  $(selector).each((index, el) => {
    positionElement =
      $(el)?.offset()?.top < positionElement
        ? $(el)?.offset()?.top
        : positionElement;
  });
  return positionElement;
};
const animationNumber = (selector, time = 1200) => {
  $(selector).each(function (index, element) {
    var currentNumber = $(element).text();
    var positionDot =
      currentNumber.indexOf(".") == -1
        ? currentNumber.indexOf(",")
        : currentNumber.indexOf(".");
    var character = currentNumber.slice(positionDot, positionDot + 1);
    var numberWithoutDot = currentNumber.replace(character, "");
    $({ numberValue: 0 }).animate(
      { numberValue: numberWithoutDot },
      {
        duration: time,
        easing: "linear",
        step: function (now) {
          let num =
            now.toFixed().slice(0, positionDot) +
            character +
            now.toFixed().slice(positionDot);
          $(element).text(num);
        },
      }
    );
  });
};
$(function () {
  if ($(".count-number")?.length) {
    var isRun = false;
    let elementPosition = getPosition(".count-number");
    if (elementPosition - screen.height < 0) {
      animationNumber(".count-number");
    } else {
      $(window).scroll(function () {
        let scroll = $(window).scrollTop();
        if (scroll > elementPosition - screen.height && !isRun) {
          isRun = true;
          animationNumber(".count-number");
        }
      });
    }
  }
});